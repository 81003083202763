import { createRouter, createWebHistory } from 'vue-router'
const { routes } = require('@/router/routes.js');
const { redirects } = require('@/router/redirects.js');

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        ...routes,
        ...redirects,
        ...[{
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import(/* webpackChunkName: "404" */'../views/404.vue'),
            meta: {
                sitemap: {
                    ignoreRoute: true,
                },
                title: "404 Page Not Found - Medunik USA Virtual Booth",
                description: "",
            },
        }]
    ]
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);

    // Find the nearest route element with language.
    const nearestWithLang = to.matched.slice().reverse().find(r => r.meta && r.meta.lang);

    // If a route with a language was found, set the document (page) lang to that value.
    if (nearestWithLang) {
        document.documentElement.lang = nearestWithLang.meta.lang;
    }

    // Find the nearest route element with title.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }

    // Find the nearest route element with description.
    const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);
    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithDescription) {
        document.querySelectorAll('meta[name="description"]').forEach(e => e.remove());
        let meta = document.createElement('meta');
        meta.setAttribute('name', 'description');
        meta.setAttribute('content', nearestWithDescription.meta.description);
        document.getElementsByTagName('head')[0].appendChild(meta);
    }

    next();
});


export default router