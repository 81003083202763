<template>
  <component
    :is="tag"
    :to="to"
    class="
      group
      inline-flex
      justify-center
      items-center
      gap-2
      p-2
      min-w-[280px]
      bg-white
      border-2 border-brown-primary
      text-brown-primary text-center text-20
      hover:bg-brown-primary hover:text-white
      transition-all
      duration-300
      ease-in
      shadow-md shadow-black/20
    "
  >
    Next
    <img
      class="
        inline-block
        group-hover:brightness-0 group-hover:invert
        transition-all
        duration-300
        ease-in
        rotate-180
      "
      src="@/assets/img/arrow.svg"
    />
  </component>
</template>

<script>
export default {
  name: "global-next-button",
  props: {
    href: String,
    to: String,
  },
  data() {
    return {};
  },
  computed: {
    tag() {
      if (this.to !== undefined) {
        return "router-link";
      } else if (this.href !== undefined) {
        return "a";
      } else {
        return "button";
      }
    },
  },
  mounted() {
    if (this.tag == "a") {
      this.$el.setAttribute("href", this.href);
    }
  },
  methods: {},
};
</script>