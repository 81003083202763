<template>
  <component
    :is="tag"
    :to="to"
    :disabled="disabled"
    class="inline-block p-5 min-w-[280px]"
    :class="[
      color === 'brown' &&
        !disabled &&
        'border-2 border-brown-primary text-brown-primary',

      disabled && 'bg-input-disabled',
    ]"
    ><slot />
  </component>
</template>

<script>
export default {
  name: "global-button",
  props: {
    href: String,
    to: String,
    color: {
      type: String,
      default: "brown",
      validator(value) {
        return ["brown"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    tag() {
      if (this.to !== undefined) {
        return "router-link";
      } else if (this.href !== undefined) {
        return "a";
      } else {
        return "button";
      }
    },
  },
  mounted() {
    if (this.tag == "a") {
      this.$el.setAttribute("href", this.href);
    }
  },
  methods: {},
};
</script>