export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */'../views/Home.vue'),
    meta: {
      sitemap: {
        changefreq: 'weekly',
      },
      title: "Medunik USA Virtual Booth",
      description: "",
    }
  },
  {
    path: '/carousel',
    name: 'Carousel',
    component: () => import(/* webpackChunkName: "carousel" */'../views/Carousel.vue'),
    meta: {
      sitemap: {
        changefreq: 'weekly',
      },
      title: "The Siklos® Story - Medunik USA Virtual Booth",
      description: "",
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "resources" */'../views/Resources.vue'),
    meta: {
      sitemap: {
        changefreq: 'weekly',
      },
      title: "Siklos® Signposts - Medunik USA Virtual Booth",
      description: "",
    }
  },
  {
    path: '/book-a-meeting',
    name: 'BookMeeting',
    component: () => import(/* webpackChunkName: "bookmeeting" */'../views/BookMeeting.vue'),
    meta: {
      sitemap: {
        changefreq: 'weekly',
      },
      title: "Siklos® Pitstop - Medunik USA Virtual Booth",
      description: "",
    }
  },
  {
    path: '/quiz',
    name: '',
    component: {
      template: "<router-view></router-view>",
    },
    meta: {
      sitemap: {
        changefreq: 'yearly',
      },
      title: "The Siklos® Race Quiz - Medunik USA Virtual Booth",
      description: "",
    },
    children: [
      {
        path: '',
        name: 'Quiz Start',
        component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Start.vue'),
      },
      {
        path: 'question',
        name: 'Quiz Question',
        component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Question.vue'),
        children: [
          {
            path: '1',
            name: 'Quiz Question 1',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/1.vue'),
          },
          {
            path: '2',
            name: 'Quiz Question 2',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/2.vue'),
          },
          {
            path: '3',
            name: 'Quiz Question 3',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/3.vue'),
          },
          {
            path: '4',
            name: 'Quiz Question 4',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/4.vue'),
          },
          {
            path: '5',
            name: 'Quiz Question 5',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/5.vue'),
          },
          {
            path: '6',
            name: 'Quiz Question 6',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/6.vue'),
          },
          {
            path: '7',
            name: 'Quiz Question 7',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/7.vue'),
          },
          {
            path: '8',
            name: 'Quiz Question 8',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/8.vue'),
          },
          {
            path: '9',
            name: 'Quiz Question 9',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/9.vue'),
          },
          {
            path: '10',
            name: 'Quiz Question 10',
            component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Questions/10.vue'),
          },
        ],
      },
      {
        path: 'finish',
        name: 'Quiz Finish',
        component: () => import(/* webpackChunkName: "quiz" */'../views/Quiz/Finish.vue'),
      },
    ],
  }
]