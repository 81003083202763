import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Import Global Components
import gBackButton from '@/components/global/BackButton'
import gNextButton from '@/components/global/NextButton'
import gButton from '@/components/global/Button'

// Import Tailwind
import './assets/tailwind.css'

const app = createApp(App)

// Add global components
app.component('gBackButton', gBackButton)
app.component('gNextButton', gNextButton)
app.component('gButton', gButton)

app.use(router)

app.mount('#app')